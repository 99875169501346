import { Link } from "gatsby"
import React from "react"
import styled from './Header.css'

const Header = ({ siteTitle }) => (
  <header>
    <div className="Header">
    <div className="splitline"></div>
      <div className="HeaderGroup">
        <Link to=""><img width="24" src={"https://yhmnin.oss-cn-beijing.aliyuncs.com/yhmnin.png"} /></Link>
        <Link to="/Showcase">Showcase</Link>
        <Link to="/SideProjects">SideProjects</Link>
        <Link to="https://teletype.in/@yhmnin" target="_blank">Blog</Link>
        <Link to="/Me">About Me</Link>
      </div>
      
    </div>
    
  </header>
)

export default Header